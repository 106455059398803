import * as React from "react";
import { Section } from "../../layout";
import "./styles.scss";

export function WhoWeAre() {
  return (
    <>
      <Section>
        <div className="grid-cols who-we-are">
          <div className="col-0">
            <h1>Our</h1>
            <h1>Story</h1>
            <div>
              <p className="small-text">
                With four decades of investing experience, we understand the
                unique aspects of investing in frontier technologies such as
                Artificial Intelligence, Robotics, Quantum Computing, Blockchain
                and others. These frontier technologies behave and evolve like a
                complex adaptive system. And identifying and capturing alpha
                requires deep knowledge of the entire ecosystem - research
                institutions, startups as well as public companies. <br />
                <br />
                Frontiers Capital is a one of its kind organization that
                leverages our unparalleled scientist network along with our
                proprietary algorithms to find this dynamic innovation and
                alpha.
              </p>
            </div>
          </div>
          <div className="col-1">
            <div>
              <img className="who crop" src="./ajay.jpg" alt="Ajay Singh" />
              <p>
                <a
                  href="https://www.linkedin.com/in/ajay-singh-255a616/"
                  title="linkedin"
                >
                  <svg width="27" height="26">
                    <path
                      d="M13.559 0c-7.179 0-13 5.821-13 13s5.821 13 13 13c7.178 0 13-5.821 13-13s-5.822-13-13-13ZM9.78 19.652H6.615v-9.525H9.78v9.525ZM8.198 8.826h-.02c-1.063 0-1.75-.731-1.75-1.645 0-.935.708-1.646 1.791-1.646s1.75.711 1.77 1.646c0 .914-.687 1.645-1.791 1.645Zm13 10.826h-3.166v-5.095c0-1.281-.459-2.154-1.604-2.154-.875 0-1.396.589-1.625 1.157-.084.204-.104.488-.104.773v5.32h-3.166s.042-8.632 0-9.526H14.7v1.349c.42-.65 1.174-1.572 2.853-1.572 2.083 0 3.645 1.36 3.645 4.287v5.461Z"
                      fill-rule="nonzero"
                    />
                  </svg>
                </a>
                Ajay Singh
              </p>
            </div>
            <div>
              <img
                className="who crop"
                src="./rupam.jpeg"
                alt="Rupam Shrivastava"
              />
              <p>
                <a href="https://www.linkedin.com/in/rupam/" title="linkedin">
                  <svg width="27" height="26">
                    <path
                      d="M13.559 0c-7.179 0-13 5.821-13 13s5.821 13 13 13c7.178 0 13-5.821 13-13s-5.822-13-13-13ZM9.78 19.652H6.615v-9.525H9.78v9.525ZM8.198 8.826h-.02c-1.063 0-1.75-.731-1.75-1.645 0-.935.708-1.646 1.791-1.646s1.75.711 1.77 1.646c0 .914-.687 1.645-1.791 1.645Zm13 10.826h-3.166v-5.095c0-1.281-.459-2.154-1.604-2.154-.875 0-1.396.589-1.625 1.157-.084.204-.104.488-.104.773v5.32h-3.166s.042-8.632 0-9.526H14.7v1.349c.42-.65 1.174-1.572 2.853-1.572 2.083 0 3.645 1.36 3.645 4.287v5.461Z"
                      fill-rule="nonzero"
                    />
                  </svg>
                </a>
                Rupam Shrivastava
              </p>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
}
