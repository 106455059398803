import * as React from "react";
import "./styles.scss";

type SocialProps = {
  className?: string;
};

export function Social(props: SocialProps) {
  return (
    <div className={["social", props.className].filter((v) => v).join(" ")}>
      <a href="https://www.twitter.com/FrontiersCapit1">
        <img src="./icons/twitter.svg" />
      </a>
      <a href="https://www.linkedin.com/company/frontierscapital">
        <img src="./icons/linkedin.svg" />
      </a>
    </div>
  );
}
