import * as React from "react";
import { Animation3 } from "../../components/Animations";
import { useWindowSize } from "../../hooks";
import { Section } from "../../layout";
import "./styles.scss";

const sections = [
  {
    name: "AI",
    displayName: "AI",
    topics: [
      "AutoML",
      "Causality",
      "Simulation",
      "Intuitive Physics",
      "Intuitive Psychology",
      "XAI",
      "Materials Discovery",
      "ML Robustness",
      "Language Understanding",
      "Privacy Preserving ML",
      "Synthetic Data",
      "Annotation CG+CV",
      "Autoregressive models",
      "Optimizers",
      "Finetuning",
      "Scene Understanding",
      "Meta learning",
    ],
  },
  {
    name: "Robotics",
    displayName: "Robotics",
    topics: [
      "Reinforcement Learning",
      "Self Driving - Sensors",
      "Self Driving - Level4 (modules)",
      "Self Driving - Level4 (ML++)",
      "Self Driving - LM delivery",
      "Self Flying - VTOL",
      "Self Flying - Level4",
      "Novel environments",
      "Uncertainity planning",
      "HMI",
      "3D Perception",
      "Real time 3D tracking",
      "Few shot adaptation",
      "Object Understanding",
      "Visual Navigation",
    ],
  },
  {
    name: "Quantum",
    displayName: "Quantum",
    topics: [
      "Cold Atoms",
      "Trapped Ions",
      "Photonics",
      "Transmons",
      "Silicon Spin",
      "Quantum Information",
      "Compiler",
      "Error Correction",
    ],
  },
  {
    name: "Immersive Computing",
    displayName: "Immersive Computing",
    topics: [
      "Smart Contract",
      "Proof-of-work systems",
      "Distributed Ledger",
      "Immutability",
      "Transactions per second",
      "Proof-of-stake",
      "Double Spending",
      "DAO",
      "Cryptocurrency",
      "Hashcash",
      "Merkle Tree",
      "Hash Functions",
      "Hash Chains",
      "P2P Protocols",
      "Distributed Hash Table",
      "Public key cryptography",
      "Byzantine fault tolerance",
      "Trusted Execution Environments",
      "Cold & Hot Wallet",
      "Sharding",
      "Non Fungible Tokens",
      "Stablecoins",
      "Consensus",
    ],
  },

  {
    name: "AR/VR",
    displayName: "AR/VR",
    topics: [
      "Direct Retinal Projection",
      "Foveal tracking",
      "Microdisplay LED",
      "Spatial Understanding",
      "MicroLED contact lens",
    ],
  },
  {
    name: "Biotech",
    displayName: "Biotech",
    topics: [
      "BCI-Non-invasive sensors",
      "BCI-Sub dermal electrodes",
      "Electro-Optics sensors",
      "Nanoelectronics",
      "Organic biolectronics",
      "BCI-Gel free EEG",
      "BCI-Hybrid sensing tracking",
      "Gene-CRISPR Cas9",
      "Gene-CRISPR Cas12",
      "Metabolic network",
      "Evolutionary Inf",
      "Proteiomics",
      "Phenomics - gene mutation",
    ],
  },
  {
    name: "Blockchain",
    displayName: "Blockchain",
    topics: [
      "Layer 1 Scalability",
      "Application Specific Blockchains",
      "Web3",
      "Decentralized Trust",
      "and others",
    ],
  },
];

const shuffle = (array: Array<string>) => {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex > 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export function Investment() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const { height, width } = useWindowSize();

  const isDesktopScreen = Number(width) > 1440;

  const activeSection = React.useMemo(() => {
    return sections[activeIndex];
  }, [activeIndex]);

  const handleMouseEnter = React.useCallback((index: number) => {
    return () => setActiveIndex(index);
  }, []);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setActiveIndex((index) => {
        if (index >= sections.length - 1) return 0;
        return index + 1;
      });
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [activeIndex]);

  const svgWidth = isDesktopScreen ? 880 : 670; // SVG width
  const svgHeight = 600; // SVG height

  const topics = React.useMemo(() => {
    return shuffle(sections.flatMap((section) => section.topics));
  }, []);

  const randomPositionArray = React.useMemo(() => {
    return topics.map(() => {
      const random = Math.random() * 10;
      const finalRandomNumber = random % 2 === 0 ? -random : random;
      return 80 + Math.round(finalRandomNumber);
    });
  }, [topics]);

  return (
    <Section>
      <div className="grid-cols investment">
        <div className="col-0">
          <h1>Our Investment</h1>
          <h1>Focus</h1>
        </div>
        <div className="investment-svg-container">
          <Animation3 />
          <svg
            className="investment-svg"
            viewBox={`0 0 ${svgWidth} 478`}
            width={svgWidth}
          >
            <defs>
              <linearGradient id="gradient" x1="0%" y1="0%">
                <stop offset="0%" stopColor="#ee62b8" />
                <stop offset="40%" stopColor="#ffac6c" />
                <stop offset="100%" stopColor=" #be73ff" />
                <animate
                  attributeName="x1"
                  dur="8s"
                  from="0%"
                  to="100%"
                  repeatCount="indefinite"
                />
              </linearGradient>
            </defs>
            <g transform={`translate(${svgWidth / 2}, ${478 / 2})`}>
              <g>
                <circle
                  r={185}
                  fill="transparent"
                  stroke="white"
                  strokeWidth={1}
                />
                {sections.map((section, i, arr) => {
                  const angle = (2 * (Math.PI * i)) / arr.length - Math.PI / 2;
                  const x = 185 * Math.cos(angle);
                  const y = 185 * Math.sin(angle);

                  let anchor = "middle";
                  let dx = 0;
                  if (Math.abs(x) > 1) {
                    if (x < 0) {
                      anchor = "end";
                      dx = -15;
                    } else if (x > 0) {
                      anchor = "start";
                      dx = 15;
                    }
                  }

                  let dy = 0;
                  if (Math.abs(y) > 1) {
                    if (y < 0) dy = -20;
                    if (y > 0) dy = 20;
                  }

                  return (
                    <g
                      onMouseEnter={handleMouseEnter(i)}
                      transform={`translate(${x}, ${y})`}
                      style={{ cursor: "pointer" }}
                    >
                      <circle r={3} fill="white" />
                      <text
                        fontSize={activeIndex === i ? 16 : 14}
                        fontWeight={activeIndex === i ? "bold" : ""}
                        fill={activeIndex === i ? "url('#gradient')" : "white"}
                        textAnchor={anchor}
                        dy={dy}
                        dx={dx}
                        textDecoration={activeIndex === i ? "underline" : ""}
                      >
                        {section.displayName}
                      </text>
                    </g>
                  );
                })}
              </g>
            </g>
            {topics.map((topic, index) => {
              const circleSpacingY = 50;
              const textYOffset = 20;

              const column = index % (svgWidth / 85);
              const row = Math.floor(index / (svgWidth / 85));

              const x = column * randomPositionArray[index];
              const y = row * circleSpacingY;
              const centerX = svgWidth / 2;
              const centerY = svgHeight / 2;
              const distanceToCenter = Math.sqrt(
                Math.pow(x - centerX, 2) + Math.pow(y - centerY, 2)
              );

              const isActive = activeSection.topics.includes(topic);

              const isWordTooLong = topic.length > 5;
              const word =
                isWordTooLong && !isActive ? `${topic.slice(0, 5)}...` : topic;

              if (distanceToCenter >= 270) {
                return (
                  <g
                    key={index}
                    transform={`translate(${x}, ${y})`}
                    opacity={isActive ? 0.8 : 0.2}
                    fill="transparent"
                  >
                    <circle r={8} cx={0} cy={0} fill="url('#gradient')" />
                    <text
                      fontSize={isDesktopScreen ? 14 : 12}
                      x={
                        x >= svgWidth / 2 ? -word.length * 2 : -word.length * 4
                      }
                      y={textYOffset}
                      fill="white"
                    >
                      {word}
                    </text>
                  </g>
                );
              }
              return null;
            })}
          </svg>
        </div>
      </div>
    </Section>
  );
}
