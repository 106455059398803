import React from "react";
import { Section } from "../../layout";
import { ScientistCard } from "./components";
import { scientists } from "./utils";
import "./styles.scss";
import { Animation2 } from "../../components/Animations";

export function Entrepreneurs() {
  return (
    <Section>
      <div className="methodology">
        <div>
          <h1>Our Scientist</h1>
          <h1>Entrepreneurs</h1>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "80px" }}>
          {scientists.map((scientist, index) => {
            return (
              <ScientistCard
                key={scientist.author}
                {...scientist}
                isLeftSide={Boolean(index % 2)}
              />
            );
          })}
        </div>
      </div>
    </Section>
  );
}
