import * as React from "react";
import "./styles.scss";

type LogoProps = {
  center?: boolean;
};

export function Logo(props: LogoProps) {
  return (
    <div className={["logo", props.center ? "logo-center" : ""].join(" ")}>
      <a href="/"><img src="./icons/frontiers.svg" /></a>
    </div>
  );
}
